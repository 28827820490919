<template>
	<div class="professional">
		<component
			@back="back"
			:is="component"
		></component>
	</div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import broadcast from "./components/broadcast";
import Microplatform from "./components/Microplatform.vue";
import Vision from "./components/Vision.vue";
import compliance from "./components/compliance.vue";
import services from "./components/services.vue";
import tanks from "./components/tanks.vue";
import home from "./components/home.vue";
import xiaoyu from "./components/xiaoyu.vue";
export default {
  name: "professional",
  //import引入的组件需要注入到对象中才能使用
  components: {
    tanks,
    services,
    compliance,
    Vision,
    Microplatform,
    broadcast,
    home,
    xiaoyu,
  },
  data() {
    //这里存放数据
    return {
      component: "home",
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    back() {
      this.component = "compliance";
    },
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    this.$bus.$on("professional", (v) => {
      this.component = v;
    });

    if (this.$route.query.type) {
      this.component = this.$route.query.type;
    }
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style lang="scss" scoped>
.professional {
}
</style>
