<template>
  <div class="stencil">
    <div class="title_text">
      <div class="back" @click="back">
        <i class="el-icon-arrow-left"></i>返回
      </div>
      <img :src="require('@/assets/bgc15.png')" alt="" class="banner" />
    </div>
    <!-- <div class="bonner">
      <img :src="require('@/assets/bannerimg8.png')" alt="" />
      <div>
        <img :src="require('@/assets/bannerimg9.png')" alt="" />
      </div>
    </div> -->
  </div>
</template>
<script>
export default {
  methods: {
    back() {
      this.$emit('back')
    },
  },
}
</script>
<style lang="scss" scoped>
.stencil {
  width: 100%;
  position: absolute;
  left: 0;
  margin-top: -25px;
  padding: 25px 0 0 0;
  background-color: #f9f9f9;

  .compilations {
    ::v-deep .el-dialog {
      height: 600px;
      overflow: auto;
      .el-dialog__body {
        padding: 0px;
        .progress {
          height: 3px;
          background-color: #4587ff;
          transition: all 0.1s;
        }
        .option {
          padding: 10px 20px;
        }
      }
    }
  }
  .title_text {
    position: absolute;
    width: 100%;
    top: -70px;
    left: 0;
    z-index: 19;
    .back {
      position: absolute;
      left: 45px;
      top: 10px;
      cursor: pointer;
      z-index: 99;
    }
    .text {
      position: absolute;
      top: 157px;
      left: 50%;
      transform: translateX(-50%);
      color: #fff;
      font-weight: bold;
      font-size: 34px;
    }
    .listval {
      position: absolute;
      top: 248px;
      left: 38%;
      font-size: 22px;
      color: #fff;
      display: flex;
      align-items: center;
      .bos {
        margin: 15px;
      }
    }

    .banner {
      width: 100%;
    }
  }
  .bonner {
    width: 100%;
    margin-top: 320px;
    img {
      width: 100%;
    }
  }

  .box {
    display: flex;
    width: 1338px;
    box-sizing: border-box;
    margin: 140px auto 0;
    .left {
      width: 936px;
      margin-right: 20px;
      background-color: #fff;
      display: flex;
      position: relative;

      .perch {
        // flex: 1;
        min-width: 190px;
        height: calc(100vh);
      }

      .mask_layer {
        position: absolute;
        left: 0;
        top: 0px;
        width: 100%;
        height: calc(100%);
        opacity: 0.2;
        background: #000;
        z-index: 2000;
      }

      .sidebar_box {
        position: absolute;
        left: 0;
        background-color: #fff;
        z-index: 2000;
        display: flex;

        .an_btn {
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: center;
          position: absolute;
          top: 50%;
          z-index: 10000;
          background-color: #ff6900;
          color: #fff;
          transform: translate(-50%);
          right: -40px;
          width: 35px;
          height: 35px;
          border-radius: 50%;
          box-shadow: 3px 1px 16px 2px rgba(107, 131, 167, 0.2);
        }

        .spin {
          transform: translate(-50%) rotate(180deg);
        }

        .absolute {
          background-color: #fff;
          position: absolute;
          left: 0;
          top: 0px;
        }

        .sidebar {
          width: 210px;
          flex: 1;
          height: calc(100vh);
          overflow-y: auto;
          z-index: 2001;
          background-color: #fff;

          .el-menu {
            border-right: 0px;
            .isSubsetClass {
              ::v-deep .el-submenu__title {
                // cursor: not-allowed;
                // color: #ccc;
                .el-submenu__icon-arrow {
                  display: none;
                }
              }
            }

            .charNumClass {
              ::v-deep .el-submenu__title {
                cursor: not-allowed;
                color: #ccc;
              }
              cursor: not-allowed;
              color: #ccc;
            }
            .submenu {
              ::v-deep .el-submenu__title {
                cursor: not-allowed;
                color: #ccc;
                .el-submenu__icon-arrow {
                  display: none;
                }
              }
            }

            .elMenuItem {
              cursor: not-allowed;
              color: #ccc;
            }
            .el-menu-item {
              height: 25px;
              line-height: 25px;
              font-size: 14px;
              -webkit-transition: none;
              transition: none;
              overflow: hidden;
              text-overflow: ellipsis;
              -webkit-line-clamp: 1;
              -webkit-box-orient: vertical;
              align-items: center;
              white-space: nowrap;
              min-width: 190px;
              .el-icon-arrow-left {
                position: absolute;
                top: 50%;
                transform: translate(0, -50%);
                left: 0px;
              }
              &:hover {
                color: #ff6900;
                border-right: #ff6900 4px solid;
                background-color: #ffbf6914 !important;
              }
            }
            .el-menu-item.is-active {
              border-right: #ff6900 4px solid;
              background-color: #ffbf6914;
            }
          }
          ::v-deep .el-submenu {
            .el-submenu__title {
              height: 25px;
              line-height: 25px;
              font-size: 14px;
              overflow: hidden;
              text-overflow: ellipsis;
              -webkit-line-clamp: 1;
              -webkit-box-orient: vertical;
              align-items: center;
              white-space: nowrap;
              .vipIcon {
                position: absolute;
                top: 50%;
                transform: translate(0, -50%);
                right: 40px;
                margin-left: 0px;
              }
              img {
                width: 13px;

                margin-left: 10px;
              }
              &:hover {
                color: #ff6900;
                background-color: #ffbf6914 !important;
              }
            }
            .el-menu {
              .is-active {
                border-right: #ff6900 4px solid;
              }
            }
          }

          .img1 {
            width: 20px;
            margin-right: 10px;
          }
          .img2 {
            width: 20px;
            margin-left: 10px;
          }
          &::-webkit-scrollbar {
            width: 0px;
            height: 0px;
          }
        }
      }

      ::v-deep .el-button {
        height: 30px;
        background: #f3f4f6;
        border: none;
        color: #ff6900;
        font-size: 14px;
        font-family: PingFang SC;
        font-weight: 500;
        padding: 0px;
        box-sizing: border-box;
        width: 56px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .color {
        color: #ff5500 !important;
      }

      .eltree {
        margin-top: -10px;
        width: 310px;
        height: 450px;
        overflow: auto;
      }

      .infoMian {
        position: fixed;
        z-index: 20;
        top: 50%;
        left: 50%;
        width: 310px;
        height: 500px;
        background-color: #fff;
        background-size: cover;
        background-repeat: no-repeat;
        font-family: Microsoft YaHei;
        transform: translate(-50%, -50%);
        border: 0px solid #818790;
        box-shadow: 0px 3px 15px 15px rgba(98, 102, 107, 0.16);
        border-radius: 5px;

        .title {
          margin-top: 10px;
          margin-left: 20px;
          color: #333;
          font-size: 16px;
          font-family: PingFang SC;
          font-weight: 400;
        }

        .xian {
          width: 280px;
          height: 10px;
          border-top: 1px solid #aaa;
          margin: 15px auto;
        }

        .cancel {
          position: absolute;
          top: 10px;
          right: 10px;
          width: 10px;
          height: 10px;
          color: #c7cbd3;
          cursor: pointer;
        }
      }

      .item {
        font-size: 18px;
        color: #ff6900;
        font-size: $font-size-small;
        font-family: PingFang SC;
        font-weight: 400;
      }

      .move {
        font-size: 18px;
        animation: move 1s;
      }

      @keyframes move {
        10% {
          bottom: -5px;
        }

        30% {
          bottom: 5px;
        }

        50% {
          bottom: -5px;
        }

        70% {
          bottom: 5px;
        }

        90% {
          bottom: -5px;
        }

        100% {
          bottom: 0px;
        }
      }

      .kong {
        height: 440px;
        text-align: center;

        img {
          margin-top: 100px;
          width: 150px;
          margin-bottom: 20px;
          height: 150px;
        }

        div {
          margin-top: 10px;
          text-align: center;
          color: #999;
          font-size: 13px;
        }
      }

      .infocompileIndex {
        height: 100%;
        position: relative;
        box-sizing: border-box;
        flex: 1;
        width: 696px;
        .banner {
          display: flex;
          align-items: center;
          padding: 0 20px;
          height: 40px;

          // border: 1px solid #eaeef1;
          background: #fbfcfd;
          .item {
            display: flex;
            align-items: center;
            margin: 0 15px;
            font-size: 14px;
            cursor: pointer;
            color: #333;
            img {
              margin-right: 5px;
              width: 16px;
              height: 16px;
            }
          }
        }
        // 主题

        &-title {
          flex-wrap: wrap;
          height: 60px;
          display: flex;
          align-items: center;
          justify-content: space-between;

          .infocompileIndex-title-item {
            cursor: pointer;
            font-size: $font-size-medium;
            font-family: PingFang SC;
            font-weight: 400;
            width: 105px;
            text-align: center;
            color: $color-text-gray-s;
          }

          .infocompileIndex-title-item:hover {
            color: $color-text-active;
          }
        }

        &-content {
          width: 100%;
          margin: 20px auto 0;
          .theme_box {
            padding: 10px 0 0;
            .title {
              font-weight: bold;
            }
          }

          &-breadcrumb {
            margin-top: 20px;
            display: flex;

            .infocompileIndex-content-breadcrumb-item {
              display: flex;
              height: 30px;
              background: #f3f4f6;
              border-radius: 15px;
              align-items: center;
              margin-left: 20px;
              cursor: pointer;

              span {
                font-size: 14px;
                font-family: PingFang SC;
                font-weight: 500;
                color: #ff6900;
              }

              .item-title {
                margin-left: 15px;
                width: 56px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
              }

              .item-num {
                margin-left: 5px;
              }

              i {
                margin-left: 5px;
                margin-right: 5px;
                cursor: pointer;
              }
            }
          }

          &-list {
            margin-top: 10px;

            &-title {
              min-width: 550px;
              padding: 0 30px;
              display: flex;
              align-items: center;

              &-left {
                display: flex;
                align-items: center;
                margin-left: 10px;

                .left-item {
                  display: flex;
                  margin-right: 20px;
                  cursor: pointer;

                  ::v-deep .el-badge__content.is-fixed {
                    top: 50%;
                    right: 0px;
                  }

                  .left-item-icon {
                    width: 14px;
                    height: 14px;
                    margin-right: 6px;
                  }

                  .icon {
                    @include backgroundGroup(
                      '~assets/images/infoCompile-icon.png'
                    );
                  }

                  .add {
                    @include backgroundGroup(
                      '~assets/images/infoCompile-add.png'
                    );
                  }

                  font-size: 14px;

                  .download {
                    @include backgroundGroup(
                      '~assets/images/infoCompile-download.png'
                    );
                  }
                  .download {
                    margin-left: 20px;
                    @include backgroundGroup('~assets/images/upload-gray.png');
                  }
                  .folder {
                    @include backgroundGroup('~assets/images/folder.png');
                  }
                }
              }

              ::v-deep .el-checkbox {
                .el-checkbox__inner {
                  width: 15px;
                  height: 15px;
                  border-color: #ff6900;
                  // background-color: #ff6900;
                }

                .is-checked {
                  .el-checkbox__inner {
                    background-color: #ff6900;
                  }
                }

                .el-checkbox__label {
                  font-size: $font-size-medium;

                  font-weight: 500;
                  color: $color-text-black;
                }
              }
              &-right {
                display: flex;
                align-items: center;
                padding: 0 20px;

                .right-item {
                  display: flex;
                  margin-right: 20px;
                  cursor: pointer;

                  .right-item-text {
                    font-size: $font-size-medium;

                    font-weight: 500;
                    color: $color-text-black;
                  }

                  .right-item-icon {
                    width: 9px;
                    height: 13px;
                    margin-left: 6px;
                  }

                  .up {
                    @include backgroundGroup(
                      '~assets/images/goldenSentence/sortIcon-blue-up.png'
                    );
                  }

                  .down {
                    @include backgroundGroup(
                      '~assets/images/goldenSentence/sortIcon-blue.png'
                    );
                  }

                  .fontColor {
                    color: #ff6900;
                  }
                }

                .right-item:last-of-type {
                  margin-right: 0px;
                }
              }
            }

            &-content {
              margin-top: 20px;
              padding: 0 10px;

              .content-item {
                min-width: 550px;
                position: relative;
                .source_version {
                  position: absolute;
                  top: 20px;
                  right: 5%;
                  display: flex;
                  align-items: center;
                  font-size: 14px;
                  // z-index: 99;
                  cursor: pointer;
                  img {
                    width: 20px;
                    height: 20px;
                  }
                }
                .content-item-icon {
                  position: absolute;
                  left: 0px;
                  top: 10px;
                  width: 34px;
                  height: 30px;
                  display: none;
                }

                .reprint {
                  @include backgroundGroup('~assets/images/info/reprint.png');
                }

                .starting {
                  @include backgroundGroup('~assets/images/info/starting.png');
                }

                .content-item-like {
                  width: 17px;
                  height: 16px;
                  cursor: pointer;
                  margin-right: 10px;
                }

                .like {
                  @include backgroundGroup('~assets/images/info/like.png');
                }

                .like-red {
                  @include backgroundGroup('~assets/images/info/like-red.png');
                }

                .content-item-text {
                  font-size: 14px;
                  font-family: PingFang SC;
                  font-weight: 400;
                  color: #000000;
                  line-height: 26px;
                  padding: 20px;

                  // span:last-of-type {
                  //   color: #ff6900;
                  //   cursor: pointer;
                  //   text-decoration: underline;
                  // }

                  .fontBlue {
                    color: #ff6900;
                  }
                }

                .title {
                  font-size: 14px;
                  padding: 20px 20px 0;
                  display: flex;
                  align-items: center;
                  .introduce-checkBox {
                    margin-right: 10px;
                    display: flex;
                    align-items: center;
                    cursor: pointer;

                    .introduce-checkBox-icon {
                      width: 16px;
                      height: 16px;
                      background-repeat: no-repeat;
                      background-size: cover;
                      background-image: url('~assets/images/info/checkBox.png');
                      margin-right: 5px;
                    }

                    .checkBox-checked {
                      background-image: url('~assets/images/info/checkBox-checked.png');
                    }
                  }

                  .title_box {
                    flex: 1;
                    font-size: 16px;
                    color: #333333;
                    cursor: pointer;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    padding-right: 150px;
                    &:hover {
                      color: #ff6900;
                    }
                  }
                }

                .content-item-introduce {
                  display: flex;
                  align-items: center;
                  font-size: 14px;
                  font-family: PingFang SC;
                  font-weight: 400;
                  color: #333333;
                  padding: 0 20px 20px;
                  border-bottom: 1px solid #efefef;

                  .introduce-radioBox {
                    margin-left: 15px;
                    display: flex;
                    align-items: center;
                    cursor: pointer;

                    .introduce-radioBox-icon {
                      width: 16px;
                      height: 16px;
                      background-repeat: no-repeat;
                      background-size: cover;
                      background-image: url('~assets/images/info/radio.png');
                      margin-right: 4px;
                    }

                    .radioBox-checked {
                      background-image: url('~assets/images/info/radio-checked.png');
                    }
                  }

                  .introduce-starBox {
                    margin-right: 15px;
                    display: flex;
                    align-items: center;

                    .introduce-starBox-icon {
                      width: 14px;
                      height: 14px;
                      background-repeat: no-repeat;
                      background-size: cover;
                      background-image: url('~assets/images/info/star.png');
                      margin-right: 5px;
                    }
                  }

                  .introduce-hotBox {
                    margin-left: 18px;
                    display: flex;
                    align-items: center;

                    .introduce-hotBox-icon {
                      width: 14px;
                      height: 14px;
                      background-repeat: no-repeat;
                      background-size: cover;
                      background-image: url('~assets/images/info/hot.png');
                      margin-right: 5px;
                    }
                  }

                  .introduce-lampBox {
                    display: flex;
                    align-items: center;
                    margin-right: 15px;

                    .introduce-lampBox-icon {
                      width: 14px;
                      height: 14px;
                      background-repeat: no-repeat;
                      background-size: cover;
                      background-image: url('~assets/images/info/lamp.png');
                      margin-right: 4px;
                    }
                  }

                  .introduce-timeBox {
                    display: flex;
                    align-items: center;

                    .introduce-timeBox-icon {
                      width: 14px;
                      height: 14px;
                      background-repeat: no-repeat;
                      background-size: cover;
                      background-image: url('~assets/images/info/time.png');
                      margin-right: 4px;
                    }

                    .introduce-timeBox-text {
                      width: 85px;
                      overflow: hidden;
                      text-overflow: ellipsis;
                      white-space: nowrap;
                    }
                  }

                  .introduce-titleBox-text {
                    width: 120px;
                    margin-left: 24px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                  }
                }

                .content-item-btnGroup {
                  display: flex;
                  align-items: center;

                  flex: 1;
                  justify-content: space-around;
                  font-size: 14px;
                  font-family: PingFang SC;
                  font-weight: 400;
                  color: #666666;

                  .btnGroup-line {
                    width: 1px;
                    height: 30px;
                    background: #d8d8d8;
                  }

                  .content-item-btnItem {
                    cursor: pointer;
                    display: flex;
                    align-items: center;

                    img {
                      margin-right: 3px;
                    }

                    .content-item-btnItem-icon {
                      width: 16px;
                      height: 16px;
                      background-repeat: no-repeat;
                      background-size: cover;
                      margin-right: 5px;
                    }

                    .download {
                      background-image: url('~assets/images/info/download.png');
                    }

                    .upload {
                      background-image: url('~assets/images/info/upload.png');
                    }

                    .relation {
                      background-image: url('~assets/images/info/relation.png');
                    }

                    .index {
                      background-image: url('~assets/images/info/index.png');
                    }

                    .copyright {
                      background-image: url('~assets/images/info/copyright.png');
                    }

                    .share {
                      background-image: url('~assets/images/info/share.png');
                    }
                  }

                  .downloadBox:hover .download {
                    background-image: url('~assets/images/info/download-red.png');
                  }

                  .uploadBox:hover .upload {
                    background-image: url('~assets/images/info/upload-red.png');
                  }

                  .relationBox:hover .relation {
                    background-image: url('~assets/images/info/relation-red.png');
                  }

                  .indexBox:hover .index {
                    background-image: url('~assets/images/info/index-red.png');
                  }

                  .copyrightBox:hover .copyright {
                    background-image: url('~assets/images/info/copyright-red.png');
                  }

                  .shareBox:hover .share {
                    background-image: url('~assets/images/info/share-red.png');
                  }

                  .content-item-btnItem:hover .content-item-btnItem-text {
                    color: #ec5e58;
                  }
                }
              }

              .content-item:hover {
                background: #ffffff;
                box-shadow: 3px 1px 16px 2px rgba(107, 131, 167, 0.1);
                border-radius: 5px;
              }
            }
          }
        }

        &-footer {
          @include noselect;
          margin: 30px 0;
          display: flex;
          justify-content: center;

          ::v-deep .el-pagination {
            button {
              width: 80px;
            }
          }
        }

        .addFil {
          ::v-deep .el-dialog {
            .el-dialog__footer {
              .dialog-footer {
                display: flex;
                justify-content: flex-end;
                align-items: center;

                .footer-btn {
                  cursor: pointer;
                  width: 71px;
                  height: 41px;
                  border: 1px solid #cecece;
                  border-radius: 3px;
                  font-size: 16px;
                  font-family: PingFang SC;
                  font-weight: 500;
                  color: #333333;
                  background: #fff;
                }

                .sure {
                  margin-right: 12px;
                  background: #ff6900;
                  color: #fff;
                }
              }
            }
          }
        }
        .compile-volume {
          ::v-deep .el-dialog {
            @include flex-center(column);
            justify-content: normal;
            align-items: normal;
            margin-top: 20vh !important;
            width: 340px;
            height: 200px;
            background: #ffffff;
            box-shadow: 0px 0px 16px 0px rgba(98, 102, 107, 0.2);
            .el-dialog__header {
              padding: 12px 12px 0px;
              .el-dialog__title {
                font-size: $font-size-small;
                font-family: PingFang SC;
                font-weight: 400;
                color: $color-text-gray;
              }
              .el-dialog__headerbtn {
                top: 12px;
                right: 12px;
              }
            }
            .el-dialog__body {
              display: flex;
              flex-direction: column;
              flex: 1;
              // padding: 35px 30;
              // width: 75%;
              .content {
                @include flex-center;
                flex: 1;
              }
              .footer {
                display: flex;
                justify-content: space-evenly;
                margin-top: 28px;
                padding: 20px 0;
                width: 100%;
                .footer-btn {
                  @include noselect;
                  font-size: $font-size-medium;
                  font-family: PingFang SC;
                  font-weight: 500;
                  color: $color-bg-heightlight;
                  cursor: pointer;
                  &.clear {
                    color: #999999;
                  }
                }
              }
            }
          }
        }
      }
    }

    .right {
      background-color: #fff;
      flex: 1;
      position: relative;
      z-index: 2;
    }
  }
}
.dataAnalysisDetails {
  // position: absolute;
  left: 0;
  top: 0px;
  // height: 100%;
  // z-index: 2003;
  background: #fff;
  width: 100%;
}
.qrcode {
  ::v-deep .el-dialog {
    // top: 50%;
    // transform: translateY(-50%);
    margin-top: 20vh !important;
    width: 500px;
    height: 390px;
    background: #ffffff;
    border: 0px solid #818790;
    box-shadow: 0px 0px 16px 0px rgba(98, 102, 107, 0.2);
    .el-dialog__header {
      height: 42px;
      background: #f4f6f7;
      padding: 0;
      line-height: 42px;
      .el-dialog__title {
        margin-left: 15px;
        font-size: $font-size-medium;
        font-family: PingFang SC;
        font-weight: 500;
        color: #393939;
      }
      .el-dialog__headerbtn {
        top: $font-size-small;
        right: $font-size-small;
        font-size: $font-size-large;
      }
    }
    .el-dialog__body {
      padding: 65px 20px;
      .content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .notes {
          font-size: $font-size-medium;
          font-family: PingFang SC;
          font-weight: 500;
          color: $color-text-black;
        }
        .imgBox {
          margin-top: 30px;
        }
      }
    }
  }
}
</style>
